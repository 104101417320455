<template>
	<div class="lesson-card">
		<div class="lesson-card__head" @click="switchState">
			<div class="lesson-card__head--text">
				<div class="lesson-card__head--text-number">
					LESSON {{ idx }}
				</div>
				<div class="lesson-card__head--text-title">
					{{ lesson.title }}
				</div>
			</div>
			<div class="lesson-card__head--contoller" :class="{inactive: !isActive}">
				<icon icon="v2-chevron-down" colour="transparent" />
			</div>
		</div>

		<transition name="slide">
			<div class="lesson-card__body" v-if="isActive">
				<div class="lesson-card__body--description">
					{{ lesson.description }}
				</div>
				<div class="lesson-card__body--labels" v-if="!isEmpty">
					<div class="lesson-card__body--labels-wrapper" v-for="(label, idx) in lessonLabels" :key="label.type">
						<div class="lesson-card__body--labels-item">
							<icon :icon="label.icon" colour="gray-d5" />
							{{ label.title }}
						</div>
						<div v-if="idx !== lessonLabels.length - 1" class="lesson-card__body--labels-separator" />
					</div>

				</div>
			</div>
		</transition>

	</div>
</template>

<script>
	import Icon         from '@/components/ui/Icon';

	export default {

		components: {
			Icon
		},

		props: {
			lesson: {
				type: Object,
				required: true
			},

			idx: {
				type: Number,
				required: true
			}
		},

		data: ({ lesson }) => {
			return {
				isActive: lesson.isOpened
			};
		},

		computed: {

			isEmpty () {
				return this.lesson.activityCount === 0;
			},

			lessonLabels () {
				const labels = this.getGroupsLabels();

        if (this.lesson.schedule?.startsAt) {
          labels.push({
            type: 'date',
            date: this.lesson.schedule?.startsAt
          });
        }

				return labels.map((item) => this.parseToLessonLabel(item));
			}
		},

		methods: {

			transformDate (dateString) {
				const date = new Date(dateString);

				// Get date parts
				const day = date.getUTCDate();
				const month = date.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
				const year = date.getUTCFullYear();

				// Get time parts
				let hours = date.getUTCHours();
				const minutes = date.getUTCMinutes();
				const ampm = hours >= 12 ? 'pm' : 'am';
				hours = hours % 12;
				hours = hours || 12; // the hour '0' should be '12'
				const minutesStr = minutes < 10 ? '0' + minutes : minutes;

				// Format the time
				const time = `${hours}:${minutesStr}${ampm}`;

				// Construct the final string
				const formattedDate = `${day} ${month} ${year} | ${time} (GMT)`;
				return formattedDate;
			},

			parseToLessonLabel (label) {
        if (label.type === 'video') {
          return {
            ...label,
            title: `${label.amount} Videos`,
            icon: 'v2-video'
          };
        }
        if (label.type === 'embeddedPresentation') {
          return {
            ...label,
            title: `${label.amount} Presentations`,
            icon: 'v2-presentation'
          };
        }

        if (label.type === 'deal') {
          return {
            ...label,
            title: `${label.amount} Deals to Play`,
            icon: 'v2-cards'
          };
        }

        if (label.type === 'quiz') {
          return {
            ...label,
            title: `${label.amount} Quizzes`,
            icon: 'v2-quiz'
          };
        }
        if (label.type === 'titlePage') {
          return {
            ...label,
            title: `${label.amount} Handouts`,
            icon: 'v2-pdf'
          };
        }

        if (label.type === 'date') {
          return {
            ...label,
            title: this.transformDate(label.date),
            icon: 'v2-date'
          };
        }

			},

			switchState () {
				this.isActive = !this.isActive;
			},

			getGroupsLabels () {
        if (!this.lesson?.activities) {
          return [];
        }

				const groups = this.lesson.activities.reduce((prev, curr) => {
					const prevIdx = prev.length;

          if (curr.type === 'quiz') {
            if (prevIdx === 0 || prev[prevIdx - 1].type !== 'quiz') {
              prev.push({
                type: curr.type
              });
            }
          } else {
            prev.push({
                type: curr.type
              });
          }

					return prev;
				}, []);

				const countedGroups = groups.reduce((prev, curr) => {

          if (prev[curr.type]) {
            prev[curr.type] += 1;
          } else {
            prev[curr.type] = 1;
          }

          return prev;
				}, {});

				const mappedCountedGroups = Object.entries(countedGroups).map((item) => {
					return {
						type: item[0],
						amount: item[1]
					};
				});

				return mappedCountedGroups;
			}
		}
	};
</script>

<style lang="scss" scoped>

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  opacity: 0;
  padding: 0;
}

.slide-enter-to, .slide-leave {
  max-height: 1000px;
  opacity: 1;
}

.lesson-card {
  display: flex;
  flex-direction: column;

  background-color: $c-bg-white;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid $c-gray-d;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--text {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-number {
        @include font(12px, $c-medium-navy, 500, 15px);
      }

      &-title {
        @include font(18px, $c-dark-navy, 500, 23px);
      }
    }

    &--contoller {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s ease-in-out;
      transform: rotate(180deg);

      &.inactive {
        transform: rotate(0deg);
      }
    }

  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 6px;

    margin-top: 16px;
    margin-bottom: 16px;
    @include font(16px, $c-medium-navy, 400, 20px);
    overflow: hidden;

    &--description {
      @include font(16px, $c-medium-navy, 400, 20px);
    }

    &--labels {
      @include font(14px, $c-gray-d5, 400, 18px);
      background: $c-gray-l2;
      padding: 12px;
      gap: 9px;
      display: flex;
      flex-wrap: wrap;

      &-wrapper {
        display: flex;
        gap: 9px;
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 4px;

        svg {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 17px;
          height: 17px;
        }
      }

      &-separator {
        display: flex;
        align-self: center;
        width: 5px;
        height: 5px;
        background: $c-light-navy;
        border-radius: 100px;
      }
    }

  }
}

</style>
