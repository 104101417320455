<template>
	<div class="page container">
		<v2-back-btn />
		<div class="course" v-if="course">
			<div class="course__header content">
				<div class="main">
					<div class="course__header--info">
						<div class="tag">
							<div class="signal" :class="[course.live ? 'live' : 'online']" />
							{{ course.live ? 'LIVE' : 'ONLINE' }}
						</div>
						<div class="tag">
							<icon icon="v2-book" colour="transparent" />
							{{ course.lessons.length }} Lessons
						</div>
						<div class="tag">
							<icon icon="v2-shield" colour="transparent" />
							{{ getLevel }}
						</div>
					</div>
					<div class="course__header--title">
						{{ course.title }}
					</div>
					<div class="course__header--subtitle">
						{{ course.description }}
					</div>

					<div class="course__header--description">
						<div
							data-element="introduction"
							v-if="course.introduction"
						>
							<div
								data-element="introduction"
								v-html="course.introduction"
							/>
						</div>
					</div>

					<div class="course__header--controls">
						<V2Button :disabled="course.activated" @click="joinCourse">
							{{ course.activated ? 'Enrolled' : 'Join Course' }}
						</V2Button>
						<template v-if="!course.activated">
							<span class="course__header--controls__price" :class="{discount: course.costWithDiscount && !course.isFree}"> {{ getCostString }} </span>
							<span v-if="course.costWithDiscount && !course.isFree" class="course__header--controls__price">{{ getDiscountString }}</span>
						</template>
						<v2-course-progress v-else :value="course.activityProgress || 0" />
					</div>
				</div>
				<div class="side">
					<img class="course__header--img" src="@/assets/icons/v2/image-placeholder.svg" />
				</div>
			</div>
			<div class="separator" />

			<div class="course__body content">
				<div class="main">
					<div class="course__body--title">
						Lessons
					</div>
					<div class="course__body--lessons">
						<v2-lesson-card-small
							v-for="(lesson, idx) in course.lessons"
							:lesson="lesson"
							:course="course"
							:active="false"
							:sell="idx === 0"
							:locked="true"
							:key="lesson.id" />
					</div>
					<div class="course__body--title">
						Course Syllabus
					</div>
					<div class="course__body--lessons-full">
						<v2-lesson-card
							v-for="(lesson, idx) in course.lessons"
							:course="course"
							:lesson="lesson"
							:idx="idx + 1"
							:key="lesson.id" />
					</div>
				</div>
				<div class="side">
					<div class="course__body--info">
						<div class="course__body--info-title">
							<icon icon="v2-bookmark" colour="transparent" />
							Key Topics We’ll Cover
						</div>

						<div class="course__body--info-labels">
							<div class="tag" v-for="tag in course.keywords" :key="tag">
								{{ tag }}
							</div>
						</div>

						<div class="course__body--info-title">
							<icon icon="v2-teacher" colour="transparent" />
							Your Instructor
						</div>

						<div class="course__body--info-teacher teacher" @click="openTeacher">
							<img class="teacher__avatar" :src="getImageSrc" />
							<div class="teacher__text">
								<div class="teacher__text--title">
									{{ course.teacherName }}
								</div>
								<div class="teacher__text--subtitle">
									Teacher
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<route>
	{
		"meta": {
			"showTopPicks": true
		}
	}
</route>

<script>

	import routeParams        from '@/mixins/routeParams';
	import api                from '@/services/api';
	import Icon               from '@/components/ui/Icon';
	import V2CourseProgress   from '@/components/v2/courses/V2CourseProgress';
	import V2BackBtn          from '@/components/v2/ui/V2BackBtn';
	import V2Button           from '@/components/v2/ui/V2Button';
	import V2LessonCardSmall  from '@/components/v2/lessons/V2LessonCardSmall';
	import V2LessonCard  from '@/components/v2/lessons/V2LessonCard';
	import getStripe             from '@/services/stripe';
	import { courseCost, courseDiscountCost } from '@/formatters';
	import MockImage from '@/assets/icons/v2/image-placeholder-small.svg';

	export default {
		metaInfo () {
			if (!this.course) {
				return false;
			}
			return {
				title: this.course.title
			};
		},

		layout: 'v2default',

		components: {
			Icon,
			V2CourseProgress,
			V2BackBtn,
			V2LessonCardSmall,
			V2LessonCard,
			V2Button
		},

		mixins: [routeParams],

		data () {
			return {
				course: null
			};
		},

		computed: {
			getLevel () {
				if (this.course.minExperienceLevel === this.course.maxExperienceLevel) {
				  return this.course.minExperienceLevel;
				}

				return `${this.course.minExperienceLevel}-${this.course.maxExperienceLevel}`;
			},

			getBreadcrumbs () {
				return [
					{
						path: '/browse-courses',
						text: 'Find a course'
					},
					{
						path: this.getCourseRoute,
						text: this.getCourseTitle
					}
				].filter(Boolean);
			},

			getCostString () {
				if (this.course.isFree) {
					return 'free';
				}
				return courseCost(this.course);
			},

			getDiscountString () {
				return courseDiscountCost(this.course);
			},

			getImageSrc () {
				if (!this.course?.teacherProfile?.media?.[0]?.url) {
					return MockImage;
				}
				return this.course.teacherProfile.media[0].url;
			}
		},

		created () {
			this.setCourse();
		},

		methods: {

			async joinCourse () {
				const course = await api.courses.joinCourseById({
					courseId: this.course.id
				});
				if (!course) {
					return false;
				}
				if (!course.checkout) {
					// no fee required, no stripe checkout
					this.$router.push(`/my-courses/${this.course.id}`);
					return;
				}
				const stripe = await getStripe();
				stripe.redirectToCheckout({
					sessionId: course.checkout
				});
			},

			openTeacher () {
				this.$router.push(`/teachers/${this.course.teacherProfileId}`);
			},

			isLessonActive (lesson) {
				const activeLessonId = this.course.lessons.find((lsn) => {
					return lsn.activities.find((activity) => activity.progress === null);
				})?.id;

				return activeLessonId === lesson.id;
			},

			async setCourse () {
				this.$store.commit('ui/setLoadingIsVisible');

				const course = await api.courses.getPublishedCourseById({
					courseId: this.getCourseId
				});

				if (!course) {
					this.$store.commit('ui/showError');
					return false;
				}

				const teacherProfile = await api.teacherProfiles.getTeacherProfileById({
					profileId: course.teacherProfileId
				});

        if (!teacherProfile) {
					this.$store.commit('ui/showError');
					return false;
				}

				course.teacherProfile = teacherProfile;

				this.course = course;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>

.course {

  &__header {
    margin-top: 24px;
    background: $c-white-l;
    padding: 20px;
    border-radius: 4px;

    .main {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .side {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--info {
      display: flex;
      gap: 6px;
      margin-bottom: 8px;

      .tag {
        background: $c-gray-l;
      }
    }

    &--title {
      @include font(28px, $c-darkest-navy, 500, 36px)
    }

    &--subtitle {
      @include font(18px, $c-darkest-navy, 400, 23px)
    }

    &--description {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      @include font(16px, $c-darkest-navy, 400, 20px);

      ::v-deep [data-element='introduction'] {
        font-size:rem(18);
        @include rich-text-content;
        @include rich-text-suits;
      }

      &-item {
        display: flex;
        align-items: center;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 13.5px;
          height: 13.5px;
          margin-right: 5.5px

        }

        .bold {
          font-weight: 700;
        }
      }

    }

    &--controls {
      display: flex;
      align-items: center;
      margin-top: 8px;
      gap: 16px;

      .btn {
        width: auto
      }

      &__price {
        @include font(20px, $c-dark-navy, 500, 26px);

        &.discount {
          text-decoration: line-through;
          opacity: 0.5;
        }
      }

      .progress-wrapper {
        width: 105px;
      }
    }

    &--img {
      width: 277px;
      height: 174px;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  &__body {
    &--info {
      display: flex;
      flex-direction: column;

      &-title {
        display: flex;
        align-items: center;
        padding: 7.5px 0;
        margin-bottom: 12px;
        gap: 10px;
        @include font(18px, $c-darkest-navy, 700, 23px);
      }

      &-labels {
        margin-bottom: 36px;
        display: flex;
        flex-wrap: wrap;
        gap: 9px;

        .tag {
          background: $c-gray-l;
        }
      }

      &-teacher {
        display: flex;
        padding: 12px;
        border: 1px solid $c-gray-d2;
        gap: 12px;
        cursor: pointer;

        .teacher {
          &__avatar {
            width: 45px;
            height: 45px;
            object-fit: cover;
            border-radius: 2px;
          }

          &__text {
            display: flex;
            flex-direction: column;
            gap: 4px;

            &--title {
              @include font(18px, $c-dark-navy, 500, 23px);
            }

            &--subtitle {
              @include font(14px, $c-medium-navy, 700, 18px);

              ::v-deep [data-element='introduction'] {
                font-size: rem(18);
                @include rich-text-content;
                @include rich-text-suits;
              }
            }
          }
        }
      }
    }

    &--title {
      @include font(24px, $c-darkest-navy, 500, 31px);
      margin-bottom: 36px;
    }

    &--lessons {
      margin-bottom: 36px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 12px;
    }

    &--lessons-full {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }
  }
}
</style>
